import { LISTINGS} from '@/constants';

export const listings = {
  [LISTINGS.PAYMENT.METHOD_KEY.BANK_TRANSFER]: 'Bonifico bancario',
  [LISTINGS.PAYMENT.METHOD_KEY.CREDIT_CARD]: 'Carta di credito',
  [LISTINGS.POSITIONINGS.GRID]: 'Griglia',
  [LISTINGS.POSITIONINGS.LIST]: 'Lista',
  [LISTINGS.SORTINGS.ALPHABET_ASC]: 'Alfabeto asc',
  [LISTINGS.SORTINGS.ALPHABET_DESC]: 'Alfabeto disc',
  [LISTINGS.SORTINGS.DATE_ASC]: 'Data asc',
  [LISTINGS.SORTINGS.DATE_DESC]: 'Data disc',
  [LISTINGS.TX.TYPE.RECEIVED]: 'Ricevute',
  [LISTINGS.TX.TYPE.SENT]: 'Inviate',
};