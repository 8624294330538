import { LISTINGS } from '@/constants';

export const listings = {
  [LISTINGS.PAYMENT.METHOD_KEY.BANK_TRANSFER]: 'Bank transfer',
  [LISTINGS.PAYMENT.METHOD_KEY.CREDIT_CARD]: 'Credit card',
  [LISTINGS.POSITIONINGS.GRID]: 'Grid',
  [LISTINGS.POSITIONINGS.LIST]: 'List',
  [LISTINGS.SORTINGS.ALPHABET_ASC]: 'Alphabet asc',
  [LISTINGS.SORTINGS.ALPHABET_DESC]: 'Alphabet desc',
  [LISTINGS.SORTINGS.DATE_ASC]: 'Date asc',
  [LISTINGS.SORTINGS.DATE_DESC]: 'Date desc',
  [LISTINGS.TX.TYPE.RECEIVED]: 'Received',
  [LISTINGS.TX.TYPE.SENT]: 'Sent',
};