import { eid } from '@/apis';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAccount({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    // TODO: add "dispatch('fetchAgreements')"
    const requests = [dispatch('fetchIdentity')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  // /**
  //  *
  //  * @param {Function} commit
  //  * @param {Object} rootGetters
  //  */
  // async fetchAgreements({ commit, rootGetters }) {
  //   const token = rootGetters['auth/token'];
  //   try {
  //     // TODO: fetch agreements
  //   } catch (error) {
  //     commit('setError', error);
  //   }
  // },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchIdentity({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await eid.requestDetail(xLocale, token);
      commit('setIdentity', response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit('setIdentity', null);
      } else {
        commit('setError', error);
      }
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAccountDashboard({ commit, dispatch }) {
    commit('setError', null);
    commit('setFetching', true);
    commit('yapily/reset', null, { root: true });
    const requests = [
      dispatch('yapily/fetchConsentList', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setFetching', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   */
  async addYapilyConsents({ commit, dispatch }, cursor) {
    commit('setAdding', true);
    await dispatch('yapily/fetchConsentList', cursor, { root: true });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async sendDeletionRequest({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      await eid.requestDelete(xLocale, token);
      return true;
    } catch (error) {
      commit('setError', error);
    }
  },
};
